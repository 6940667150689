<template>
  <div class="min-h-screen bg-gray-900 text-white p-8">
    <div class="max-w-md mx-auto">
      <!-- Banner -->
      <div class="flex justify-center -mx-8 -mt-8 mb-8">
        <img src="../assets/banner.png" alt="Dewormer Banner" class="w-full">
      </div>

      <!-- Back Button -->
      <button @click="$router.push('/')" 
              class="mb-6 flex items-center gap-2 text-gray-400 hover:text-white transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        Back to Home
      </button>

      <!-- Wallet Info Section -->
      <div v-if="walletConnected" class="bg-gray-800 rounded-xl p-4 mb-6 border border-gray-700">
        <div class="flex items-center justify-between mb-2">
          <span class="text-sm text-gray-400">Connected Wallet</span>
          <span class="text-sm font-mono bg-gray-700 px-2 py-1 rounded">
            {{ shortenAddress(wallet.publicKey.toString()) }}
          </span>
        </div>
        <div class="flex items-center justify-between mb-3">
          <span class="text-sm text-gray-400">$IVER Balance</span>
          <span class="text-sm font-mono" :class="{'text-yellow-500': iverBalance === '0'}">
            {{ iverBalance === null ? 'Loading...' : iverBalance }}
          </span>
        </div>
        <a href="https://dexscreener.com/solana/bunjl9uifgveryecsnmvfjs1j9u7u3nbprbmxcamqbux"
           target="_blank"
           rel="noopener noreferrer"
           class="block max-w-[240px] mx-auto bg-[#00B8B9] hover:bg-[#00A5A6] text-white text-sm font-semibold py-2 px-4 rounded-lg transition-colors text-center">
          Get $IVER on Dexscreener
        </a>
      </div>

      <div class="bg-gray-800 rounded-xl p-6 shadow-lg">
        <h1 class="text-2xl font-bold mb-6 text-center">Create X Post NFT</h1>
        
        <div v-if="!walletConnected" class="mb-6">
          <button @click="connectWallet" 
                  class="w-full bg-[#AB9FF2] hover:bg-[#9A8BE0] text-white font-bold py-3 px-4 rounded-lg transition-colors flex items-center justify-center gap-2">
            <img src="../assets/Phantom-Icon_App.svg" alt="Phantom Wallet" class="w-6 h-6">
            Connect Phantom Wallet
          </button>
        </div>

        <div v-else>
          <div class="mb-4">
            <div class="flex justify-between items-center mb-2">
              <label class="block text-sm font-medium">X Post URL</label>
              <span class="text-xs text-gray-400">{{ xPostUrl.length }}/100</span>
            </div>
            <input v-model="xPostUrl" 
                   type="text" 
                   maxlength="100"
                   placeholder="https://x.com/username/status/123456789"
                   class="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500">
            <p class="mt-1 text-xs text-gray-400">Enter the full URL of the X post</p>
          </div>

          <div class="mb-4">
            <div class="flex justify-between items-center mb-2">
              <label class="block text-sm font-medium">NFT Name</label>
              <span class="text-xs text-gray-400">{{ nftName.length }}/32</span>
            </div>
            <input v-model="nftName" 
                   type="text" 
                   maxlength="32"
                   placeholder="My NFT"
                   class="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500">
          </div>

          <div class="mb-6">
            <div class="flex justify-between items-center mb-2">
              <label class="block text-sm font-medium">Reason for Deworming</label>
              <span class="text-xs text-gray-400">{{ dewormingReason.length }}/140</span>
            </div>
            <textarea 
              v-model="dewormingReason"
              maxlength="140"
              rows="3"
              placeholder="Why should this X post be immortalized as an NFT?"
              class="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 resize-none"
            ></textarea>
          </div>

          <button @click="createNFT" 
                  :disabled="!canCreateNFT || parseFloat(iverBalance || '0') < 10000 || isLoading"
                  class="w-full bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
            {{ isLoading ? 'Creating NFT...' : 'Create NFT (Cost: 10,000 $IVER)' }}
          </button>

          <p v-if="error" class="mt-4 text-red-500 text-sm">{{ error }}</p>
          <p v-if="success" class="mt-4 text-green-500 text-sm">{{ success }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Connection, PublicKey } from '@solana/web3.js';
import { getAssociatedTokenAddress, getAccount, createBurnCheckedInstruction } from '@solana/spl-token';
import { supabase, initializeStorage } from '../@supabase';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { transactionBuilder, generateSigner, publicKey } from '@metaplex-foundation/umi';
import { createNft, TokenStandard, mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata';
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { mplToolbox } from '@metaplex-foundation/mpl-toolbox';
import PikaSdk from '../utils/pika-sdk';

export default {
  name: 'NFTCreator',
  setup() {
    const router = useRouter();
    if (!router) {
      console.error('Router not initialized');
      throw new Error('Router not initialized');
    }

    // Reactive Properties
    const walletConnected = ref(false);
    const xPostUrl = ref('');
    const nftName = ref('');
    const dewormingReason = ref('');
    const error = ref('');
    const success = ref('');
    const wallet = ref(null);
    const iverBalance = ref(null);
    const isLoading = ref(false);

    const IVER_TOKEN_ADDRESS = new PublicKey('EViycs9ZowdRH2zeaSgAeuWCzetTMDK3ghXPMEoipump');

    const canCreateNFT = computed(() => {
      return walletConnected.value &&
             xPostUrl.value.trim() &&
             nftName.value.trim() &&
             dewormingReason.value.trim();
    });

    const shortenAddress = (address) => {
      if (!address) return '';
      return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const connectWallet = async () => {
      try {
        if (!window.solana) {
          error.value = 'Please install Phantom wallet';
          return;
        }

        const response = await window.solana.connect();
        wallet.value = window.solana;
        walletConnected.value = true;
        error.value = '';
        await fetchBalances();
      } catch (err) {
        console.error('Connection error:', err);
        error.value = 'Failed to connect wallet: ' + err.message;
      }
    };

    const fetchBalances = async () => {
      try {
        if (!wallet.value?.publicKey) return;

        const connection = new Connection(process.env.VUE_APP_SOLANA_RPC_URL);

        // Get IVER token account address
        const tokenAccountAddress = await getAssociatedTokenAddress(
          IVER_TOKEN_ADDRESS,
          wallet.value.publicKey
        );

        try {
          // Get token account info
          const tokenAccount = await getAccount(connection, tokenAccountAddress);
          // Convert amount considering 6 decimals (IVER uses 6 decimals)
          iverBalance.value = (Number(tokenAccount.amount) / Math.pow(10, 6)).toString();
          console.log('IVER balance:', iverBalance.value);
        } catch (err) {
          console.log('No IVER token account found:', err);
          iverBalance.value = '0';
        }

      } catch (err) {
        console.error('Error fetching balances:', err);
        iverBalance.value = '0';
      }
    };

    // Initialize Pika SDK with your API key
    const pikaSdk = new PikaSdk(`sk-${process.env.VUE_APP_PIKA_API_KEY}`);

    const createNFT = async () => {
      try {
        isLoading.value = true;
        error.value = '';
        success.value = '';

        if (!wallet.value?.publicKey) {
          throw new Error('Wallet not connected');
        }

        // Check if X post URL has already been used
        const { data: existingNFTs, error: queryError } = await supabase
          .from('nfts')
          .select('id, x_post_url')
          .eq('x_post_url', xPostUrl.value)
          .limit(1);

        if (queryError) {
          throw new Error('Failed to check for existing NFTs: ' + queryError.message);
        }

        if (existingNFTs && existingNFTs.length > 0) {
          throw new Error('This X post has already been dewormed! Please choose a different post.');
        }

        // Parse tweet URL
        const tweetUrl = xPostUrl.value;
        const regex = /x\.com\/([^/]+)\/status\/(\d+)/;
        const match = tweetUrl.match(regex);
        if (!match) {
          throw new Error('Invalid Tweet URL');
        }

        const username = match[1];
        const tweetId = match[2];

        success.value = 'Capturing tweet...';

        try {
          // Capture the tweet image using Pika SDK
          const response = await pikaSdk.generateImageFromTemplate(
            "tweet-image",
            {
              tweetUrl: xPostUrl.value,
              tweetId: tweetId,
              hideMetrics: false,
              hideVerifiedIcon: false,
              tweetFontSize: 2,
              tweetBackgroundColor: "#15202b",
              tweetTextColor: "#ffffff",
              hideDateTime: false,
              backgroundColor: "#15202b",
              padding: 50,
              width: 550
            }
          );

          if (!response.data?.base64) {
            throw new Error('No screenshot data received from the server');
          }

          success.value = 'Checking image content...';

          // Send base64 image for moderation check
          const verifyApiUrl = process.env.VUE_APP_VERIFY_API_URL;
          if (!verifyApiUrl) {
            throw new Error('API URL not configured');
          }

          const moderationResponse = await fetch(`${verifyApiUrl}/moderate-image`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
              image: response.data.base64  // Keep the data:image/ prefix
            })
          });

          switch (moderationResponse.status) {
            case 200:
              // Image approved, continue with the process
              break;
            case 400:
              const violationData = await moderationResponse.json();
              throw new Error(violationData.message || 'Image content not allowed');
            case 429:
              throw new Error('Rate limit exceeded. Please try again later.');
            case 500:
              throw new Error('Server error during image moderation. Please try again.');
            default:
              throw new Error('Unexpected error during image moderation.');
          }

          success.value = 'Uploading image...';

          // Convert base64 to blob for Supabase storage
          const imageBlob = await fetch(response.data.base64)
            .then(res => res.blob());

          // Upload image to Supabase Storage
          const fileName = `${Date.now()}-${wallet.value.publicKey.toString()}.png`;
          const { data: uploadData, error: uploadError } = await supabase.storage
            .from('nft-images')
            .upload(fileName, imageBlob, {
              contentType: 'image/png',
              cacheControl: '3600',
              upsert: false
            });

          if (uploadError) throw uploadError;

          // Get the public URL of the uploaded image
          const imageUrlData = supabase.storage
            .from('nft-images')
            .getPublicUrl(fileName);

          const imageUri = imageUrlData.data.publicUrl;

          // Create metadata JSON
          const metadata = {
            name: nftName.value,
            symbol: 'DEWORMED',
            description: dewormingReason.value,
            image: imageUri,
            collection: {
              name: "Dewormings",
              family: "Dewormings",
              address: "9GYXkhxwysGsqGuHzYdVgeJsk6uz6XR8xgULNesNTjYh"
            },
            attributes: [
              {
                trait_type: 'X Post URL',
                value: xPostUrl.value
              },
              {
                trait_type: 'X Post ID',
                value: tweetId
              }
            ],
            properties: {
              files: [{ uri: imageUri, type: "image/png" }],
              category: "image",
              creators: [{ address: wallet.value.publicKey.toString(), share: 100 }],
            }
          };

          // Upload metadata to Supabase Storage
          const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });
          const metadataFileName = `${Date.now()}-${wallet.value.publicKey.toString()}-metadata.json`;

          const { data: metadataUploadData, error: metadataUploadError } = await supabase.storage
            .from('nft-metadata')
            .upload(metadataFileName, metadataBlob, {
              contentType: 'application/json',
              cacheControl: '3600',
              upsert: false
            });

          if (metadataUploadError) throw metadataUploadError;

          // Get the public URL of the metadata
          const metadataUrlData = supabase.storage
            .from('nft-metadata')
            .getPublicUrl(metadataFileName);

          const metadataUri = metadataUrlData.data.publicUrl;

          success.value = 'Creating NFT...';

          // Initialize UMI with all required programs
          const umi = createUmi(process.env.VUE_APP_SOLANA_RPC_URL)
            .use(mplTokenMetadata())
            .use(mplToolbox())
            .use(walletAdapterIdentity(wallet.value));

          // Create NFT mint
          const mint = generateSigner(umi);

          // Set priority fee
          const priorityFee = {
            computeUnits: 1_400_000,
            microLamports: 1_000_000
          };

          // Now call createNFTWithRetry with the necessary context including metadataUri
          const signature = await createNFTWithRetry({ umi, mint, priorityFee, metadataUri });

          // Save to database
          const { error: dbError } = await supabase
            .from('nfts')
            .insert([{
              address: mint.publicKey.toString(),
              name: nftName.value,
              creator: wallet.value.publicKey.toString(),
              x_post_url: xPostUrl.value,
              deworming_reason: dewormingReason.value,
              image_uri: imageUri,
              metadata_uri: metadataUri
            }]);

          if (dbError) throw dbError;

          // Reset form and show success immediately
          success.value = 'NFT created successfully!';
          xPostUrl.value = '';
          nftName.value = '';
          dewormingReason.value = '';
          await fetchBalances();

          // Send verification request to backend without waiting
          if (verifyApiUrl) {
            fetch(`${verifyApiUrl}/verify-collection`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify({ 
                nftMintAddress: mint.publicKey.toString(),
                creatorAddress: wallet.value.publicKey.toString()
              })
            }).catch(err => {
              console.warn('Verification request sent but may have failed:', err);
            });
          }

          // Navigate using the router instance
          try {
            await router.push({
              name: 'NFTDetails',
              params: { address: mint.publicKey.toString() }
            });
          } catch (routerError) {
            console.error('Navigation error:', routerError);
            // Fallback to direct path if named route fails
            await router.push(`/nft/${mint.publicKey.toString()}`);
          }

        } catch (error) {
          console.error('Error in screenshot capture:', error);
          throw error;
        }
      } catch (err) {
        console.error('Creation error:', err);
        error.value = err.message;
      } finally {
        isLoading.value = false;
      }
    };

    const convertSolanaInstructionToUmi = (instruction) => {
      return {
        programId: instruction.programId,
        keys: instruction.keys.map((key) => ({
          pubkey: publicKey(key.pubkey.toString()),
          isSigner: key.isSigner,
          isWritable: key.isWritable,
        })),
        data: instruction.data,
      };
    };

    const createNFTWithRetry = async ({ umi, mint, priorityFee, metadataUri }, retries = 3) => {
  try {
    // Get user's associated token account for $IVER
    const tokenAccountAddress = await getAssociatedTokenAddress(
      IVER_TOKEN_ADDRESS,
      wallet.value.publicKey
    );

    // Burn amount in base units (10,000 IVER * 1,000,000)
    const burnAmount = BigInt(10_000_000_000); // 10,000 * 1,000,000

    // Create burn instruction using '@solana/spl-token'
    const burnInstruction = createBurnCheckedInstruction(
      tokenAccountAddress,
      IVER_TOKEN_ADDRESS,
      wallet.value.publicKey,
      burnAmount,
      6 // Number of decimals
    );

    // Convert burn instruction to Umi instruction
    const umiBurnInstruction = convertSolanaInstructionToUmi(burnInstruction);

    // Create NFT builder
    const nftBuilder = createNft(umi, {
      mint,
      name: nftName.value,
      symbol: 'DEWORMED',
      uri: metadataUri,
      sellerFeeBasisPoints: 300,
      tokenStandard: TokenStandard.NonFungible,
      creators: [{
        address: publicKey(wallet.value.publicKey.toString()),
        share: 100,
        verified: false
      }],
      collection: {
        key: publicKey('9GYXkhxwysGsqGuHzYdVgeJsk6uz6XR8xgULNesNTjYh'), // Collection address
        verified: false
      },
      uses: null,
      ruleSet: null,
      isMutable: true,
    });

    // Build transaction builder with burn and NFT instructions
    const builder = transactionBuilder()
      .add({
        instruction: umiBurnInstruction,
        signers: [], // Umi handles signers automatically
        key: 'burnInstruction'
      })
      .add(nftBuilder);

    // Remove manual blockhash fetching and options
    // const blockhashWithExpiryBlockHeight = await umi.rpc.getLatestBlockhash();

    // Send transaction without specifying blockhash and lastValidBlockHeight
    const { signature } = await builder.sendAndConfirm(umi, {
      send: {
        skipPreflight: true,
        maxRetries: 3,
      },
      confirm: {
        commitment: 'confirmed',
        timeout: 120000, // Increase timeout to 2 minutes if necessary
      },
      priorityFee,
      // Remove blockhash and lastValidBlockHeight
      // blockhash: blockhashWithExpiryBlockHeight.blockhash,
      // lastValidBlockHeight: blockhashWithExpiryBlockHeight.lastValidBlockHeight,
    });

    // Transaction sent successfully
    return signature;

  } catch (err) {
    console.error('Transaction error:', err);

    // Retry logic
    if ((err.message.includes('expired') || err.message.includes('timeout')) && retries > 0) {
      console.log(`Retrying NFT creation... ${retries} attempts left`);
      await new Promise(resolve => setTimeout(resolve, 5000));
      return createNFTWithRetry({ umi, mint, priorityFee, metadataUri }, retries - 1);
    }

    throw err;
  }
};

    onMounted(async () => {
      try {
        // Initialize storage buckets
        await initializeStorage();

        // Check if wallet is connected
        if (window.solana?.isConnected) {
          wallet.value = window.solana;
          walletConnected.value = true;
          await fetchBalances();
        }
      } catch (err) {
        console.error('Error initializing:', err);
        error.value = 'Failed to initialize storage: ' + err.message;
      }
    });

    return {
      walletConnected,
      xPostUrl,
      nftName,
      dewormingReason,
      error,
      success,
      wallet,
      iverBalance,
      canCreateNFT,
      connectWallet,
      createNFT,
      shortenAddress,
      isLoading
    };
  }
};
</script>


<style scoped>
/* No additional styles needed as we're using Tailwind classes */
</style>
