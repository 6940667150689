<template>
  <router-view></router-view>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #111827;
}

#app {
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>
