<template>
  <div class="min-h-screen bg-gray-900 text-white p-8">
    <div class="max-w-md mx-auto">
      <!-- Banner -->
      <div class="flex justify-center -mx-8 -mt-8 mb-8">
        <img src="../assets/banner.png" alt="Dewormer Banner" class="w-full">
      </div>

      <!-- Back Button -->
      <button @click="$router.push('/')" 
              class="mb-6 flex items-center gap-2 text-gray-400 hover:text-white transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        Back to Home
      </button>

      <!-- Loading State -->
      <div v-if="loading" class="text-center py-8">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto"></div>
        <p class="mt-4 text-gray-400">Loading NFT details...</p>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="bg-red-900/50 text-red-200 p-4 rounded-lg">
        {{ error }}
      </div>

      <!-- NFT Details -->
      <div v-else-if="nft" class="bg-gray-800 rounded-xl p-6">
        <img 
          :src="nft.image_uri" 
          :alt="nft.name"
          class="w-full h-auto rounded-lg shadow-lg mb-6"
        />

        <h1 class="text-2xl font-bold mb-4">{{ nft.name }}</h1>
        
        <div class="space-y-4">
          <div>
            <h2 class="text-sm text-gray-400 mb-1">Reason for Deworming</h2>
            <p class="text-gray-200">{{ nft.deworming_reason }}</p>
          </div>

          <div>
            <h2 class="text-sm text-gray-400 mb-1">X Post</h2>
            <a 
              :href="nft.x_post_url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-400 hover:text-blue-300 break-all"
            >
              {{ nft.x_post_url }}
            </a>
          </div>

          <div>
            <h2 class="text-sm text-gray-400 mb-1">Created</h2>
            <p class="text-gray-200">{{ formatDate(nft.created_at) }}</p>
          </div>

          <div>
            <h2 class="text-sm text-gray-400 mb-1">NFT Address</h2>
            <p class="font-mono text-sm text-gray-200 break-all">{{ nft.address }}</p>
          </div>

          <!-- Share on X Button -->
          <div class="mt-6">
            <a 
              :href="generateShareUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="w-full bg-black hover:bg-gray-900 text-white text-center py-2 px-4 rounded-lg transition-colors flex items-center justify-center gap-2"
            >
              Share on
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
              </svg>
              
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { supabase } from '../@supabase'

export default {
  name: 'NFTDetails',
  setup() {
    const route = useRoute()
    const nft = ref(null)
    const loading = ref(true)
    const error = ref(null)

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    }

    const loadNFTDetails = async () => {
      try {
        const { data, error: queryError } = await supabase
          .from('nfts')
          .select('*')
          .eq('address', route.params.address)
          .single()

        if (queryError) throw queryError

        if (!data) {
          throw new Error('NFT not found')
        }

        nft.value = data
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    const generateShareUrl = computed(() => {
      if (!nft.value) return '';
      
      // Extract tweet ID from the X post URL
      const tweetMatch = nft.value.x_post_url.match(/x\.com\/[^/]+\/status\/(\d+)/);
      if (!tweetMatch) return '';
      
      const tweetId = tweetMatch[1];
      const baseUrl = 'https://x.com/intent/tweet';
      const nftUrl = `${window.location.origin}/nft/${nft.value.address}`;
      const text = `You've been dewormed! ${nft.value.deworming_reason}\n\n${nftUrl}`;
      
      return `${baseUrl}?text=${encodeURIComponent(text)}&in_reply_to=${tweetId}`;
    });

    onMounted(() => {
      loadNFTDetails()
    })

    return {
      nft,
      loading,
      error,
      formatDate,
      generateShareUrl
    }
  }
}
</script> 