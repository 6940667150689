import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './components/HomeView.vue'
//import TokenCreator from './components/TokenCreator.vue'
//import TokenDetails from './components/TokenDetails.vue'
import NFTCreator from './components/NFTCreator.vue'
import NFTDetails from './components/NFTDetails.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  // {
  //   path: '/create',
  //   name: 'TokenCreator',
  //   component: TokenCreator
  // },
  // {
  //   path: '/token/:address',
  //   name: 'TokenDetails',
  //   component: TokenDetails
  // },
  {
    path: '/create-nft',
    name: 'NFTCreator',
    component: NFTCreator
  },
  {
    path: '/nft/:address',
    name: 'NFTDetails',
    component: NFTDetails
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router 