<template>
  <div class="min-h-screen bg-gray-900 text-white p-8">
    <div class="max-w-md mx-auto">
      <!-- Banner -->
      <div class="flex justify-center -mx-8 -mt-8 mb-8">
        <img src="../assets/banner.png" alt="Dewormer Banner" class="w-full">
      </div>

      <!-- Create Token Button -->
      <div class="mb-8 flex justify-center">
        <router-link 
          to="/create-nft"
          class="max-w-[240px] bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-bold py-3 px-4 rounded-lg transition-colors text-center"
        >
          Deworm the world
        </router-link>
      </div>

      <!-- Deworming List -->
      <div class="space-y-4">
        <h2 class="text-xl font-bold mb-6">Recent Dewormings</h2>
        
        <!-- Loading State -->
        <div v-if="loading" class="text-center py-8">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto"></div>
          <p class="mt-4 text-gray-400">Loading dewormings...</p>
        </div>

        <!-- Error State -->
        <div v-else-if="error" class="bg-red-900/50 text-red-200 p-4 rounded-lg">
          {{ error }}
        </div>

        <!-- Combined List -->
        <div v-else-if="dewormings.length > 0" class="space-y-4">
          <div 
            v-for="item in dewormings" 
            :key="item.id"
            class="bg-gray-800 rounded-xl p-4 hover:bg-gray-800/80 transition-colors"
          >
            <router-link :to="getItemLink(item)">
              <!-- NFT Image -->
              <div v-if="item.type === 'nft' && item.image_uri" class="mb-4">
                <img 
                  :src="item.image_uri" 
                  :alt="item.name"
                  class="w-full h-auto rounded-lg shadow-lg"
                  loading="lazy"
                />
              </div>

              <!-- Title and Date -->
              <div class="flex justify-between items-start mb-2">
                <h3 class="font-bold text-lg">{{ item.name }}</h3>
                <span class="text-xs text-gray-500">
                  {{ formatDate(item.created_at) }}
                </span>
              </div>

              <!-- Deworming Reason -->
              <p class="text-sm text-gray-400">
                {{ item.deworming_reason }}
              </p>
            </router-link>
          </div>

          <!-- Load More Button -->
          <div v-if="hasMore" class="text-center pt-4">
            <button 
              @click="loadMore"
              class="bg-gray-800 hover:bg-gray-700 text-white px-6 py-2 rounded-lg transition-colors"
              :disabled="loadingMore"
            >
              <span v-if="loadingMore">Loading...</span>
              <span v-else>Load More</span>
            </button>
          </div>
        </div>

        <!-- Empty State -->
        <div v-else class="text-center py-8 text-gray-400">
          <p>No dewormings have been created yet.</p>
          <p class="mt-2">Be the first to deworm someone!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../supabase'

export default {
  name: 'HomeView',
  setup() {
    const dewormings = ref([])
    const loading = ref(true)
    const loadingMore = ref(false)
    const error = ref(null)
    const hasMore = ref(true)
    const currentPage = ref(0)
    const PAGE_SIZE = 10

    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
        Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24)),
        'day'
      )
    }

    const getXUsername = (url) => {
      try {
        const match = url.match(/x\.com\/([^/]+)/)
        return match ? match[1] : 'Unknown'
      } catch (err) {
        return 'Unknown'
      }
    }

    const getItemLink = (item) => {
      return item.type === 'nft' ? `/nft/${item.address}` : `/token/${item.address}`
    }

    const loadDewormings = async (isLoadMore = false) => {
      try {
        if (!isLoadMore) {
          loading.value = true
        } else {
          loadingMore.value = true
        }

        // Get tokens
        const { data: tokens, error: tokensError } = await supabase
          .from('tokens')
          .select('*, created_at')
          .order('created_at', { ascending: false })

        if (tokensError) throw tokensError

        // Get NFTs
        const { data: nfts, error: nftsError } = await supabase
          .from('nfts')
          .select('*, created_at')
          .order('created_at', { ascending: false })

        if (nftsError) throw nftsError

        // Combine and sort both types
        const combinedDewormings = [
          ...tokens.map(t => ({ ...t, type: 'token' })),
          ...nfts.map(n => ({ ...n, type: 'nft' }))
        ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

        // Paginate results
        const start = currentPage.value * PAGE_SIZE
        const end = start + PAGE_SIZE
        const paginatedResults = combinedDewormings.slice(start, end)

        if (isLoadMore) {
          dewormings.value = [...dewormings.value, ...paginatedResults]
        } else {
          dewormings.value = paginatedResults
        }

        hasMore.value = end < combinedDewormings.length

      } catch (err) {
        error.value = 'Failed to load dewormings: ' + err.message
      } finally {
        loading.value = false
        loadingMore.value = false
      }
    }

    const loadMore = async () => {
      currentPage.value++
      await loadDewormings(true)
    }

    onMounted(() => {
      loadDewormings()
    })

    return {
      dewormings,
      loading,
      loadingMore,
      error,
      hasMore,
      formatDate,
      loadMore,
      getXUsername,
      getItemLink
    }
  }
}
</script> 