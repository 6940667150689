class PikaSdk {
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  async generateImageFromTemplate(templateId, modifications, responseFormat = 'base64') {
    const response = await fetch(`https://api.pika.style/v1/templates/${templateId}/images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey}`
      },
      body: JSON.stringify({
        response_format: responseFormat,
        modifications
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to generate image');
    }

    return response.json();
  }
}

export default PikaSdk; 