import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL || ''
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY || ''

export const supabase = createClient(supabaseUrl, supabaseKey)

// Initialize storage buckets
export const initializeStorage = async () => {
  try {
    // Create nft-images bucket if it doesn't exist
    const { data: bucketData, error: bucketError } = await supabase.storage.createBucket('nft-images', {
      public: true,
      allowedMimeTypes: ['image/png', 'image/jpeg'],
      fileSizeLimit: 5242880 // 5MB
    })
    
    if (bucketError && !bucketError.message.includes('already exists')) {
      throw bucketError
    }

    // Create nft-metadata bucket if it doesn't exist
    const { data: metadataBucketData, error: metadataBucketError } = await supabase.storage.createBucket('nft-metadata', {
      public: true,
      allowedMimeTypes: ['application/json'],
      fileSizeLimit: 1048576 // 1MB
    })
    
    if (metadataBucketError && !metadataBucketError.message.includes('already exists')) {
      throw metadataBucketError
    }

    // Set public bucket policies
    const { error: policyError } = await supabase.storage.from('nft-images').updateBucket({
      public: true,
      allowedMimeTypes: ['image/png', 'image/jpeg'],
      fileSizeLimit: 5242880
    })

    if (policyError) throw policyError

    const { error: metadataPolicyError } = await supabase.storage.from('nft-metadata').updateBucket({
      public: true,
      allowedMimeTypes: ['application/json'],
      fileSizeLimit: 1048576
    })

    if (metadataPolicyError) throw metadataPolicyError

    console.log('Storage buckets initialized successfully')
    return true
  } catch (error) {
    console.error('Error initializing storage:', error)
    throw error
  }
} 