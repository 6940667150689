import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL || ''
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY || ''

export const supabase = createClient(supabaseUrl, supabaseKey)

// Initialize storage buckets
export const initializeStorage = async () => {
  try {
    // Check if buckets exist first
    const { data: buckets, error: listError } = await supabase.storage.listBuckets()
    
    if (listError) {
      throw listError
    }

    const nftImagesBucket = buckets.find(b => b.name === 'nft-images')
    const nftMetadataBucket = buckets.find(b => b.name === 'nft-metadata')

    // Create nft-images bucket if it doesn't exist
    if (!nftImagesBucket) {
      const { error: bucketError } = await supabase.storage.createBucket('nft-images', {
        public: true,
        fileSizeLimit: 5242880 // 5MB
      })
      
      if (bucketError) {
        console.error('Error creating nft-images bucket:', bucketError)
      }
    }

    // Create nft-metadata bucket if it doesn't exist
    if (!nftMetadataBucket) {
      const { error: metadataBucketError } = await supabase.storage.createBucket('nft-metadata', {
        public: true,
        fileSizeLimit: 1048576 // 1MB
      })
      
      if (metadataBucketError) {
        console.error('Error creating nft-metadata bucket:', metadataBucketError)
      }
    }

    console.log('Storage buckets initialized successfully')
    return true
  } catch (error) {
    // Log the error but don't throw it - this allows the app to continue even if storage init fails
    console.error('Error initializing storage:', error)
    return false
  }
} 